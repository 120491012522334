import {Actions} from "../actions/action";
import {
    MasterData,
} from "@/models/Masterdata";

const initialMasterDataState: MasterData = {
    airports: [],
    importLocations: [],
    exportLocations: [],
    warehouses: [],
    importers: [],
    exporters: [],
    possibleLaneCombinations: [],
    loadingUnitDestinationLmcCombination: [],
    loadingUnitDestination: [],
    loadingUnitLmcs: [],
    cities: []
}

export default (state = initialMasterDataState, action: any) => {
    switch (action.type) {
        case Actions.MASTER_DATA.SET_AIRPORTS: {
            return {...state, airports: action.payload};
        }
        case Actions.MASTER_DATA.SET_IMPORT_LOCATIONS: {
            return {...state, importLocations: action.payload};
        }
        case Actions.MASTER_DATA.SET_EXPORT_LOCATIONS: {
            return {...state, exportLocations: action.payload};
        }
        case Actions.MASTER_DATA.SET_WAREHOUSES: {
            return {...state, warehouses: action.payload};
        }
        case Actions.MASTER_DATA.SET_IMPORTERS: {
            return {...state, importers: action.payload};
        }
        case Actions.MASTER_DATA.SET_EXPORTERS: {
            return {...state, exporters: action.payload};
        }
        case Actions.MASTER_DATA.SET_POSSIBLE_LANE_COMBINATIONS: {
            return {...state, possibleLaneCombinations: action.payload};
        }
        case Actions.MASTER_DATA.SET_LOADINGUNIT_DESTINATION_LMC_COMBINATION: {
            return {...state, loadingUnitDestinationLmcCombination: action.payload};
        }
        case Actions.MASTER_DATA.SET_LOADINGUNIT_LMCS: {
            return {...state, loadingUnitLmcs: action.payload};
        }
        case Actions.MASTER_DATA.SET_LOADINGUNIT_DESTINATIONS: {
            return {...state, loadingUnitDestination: action.payload};
        }
        case Actions.MASTER_DATA.SET_CITIES: {
            return {...state, cities: action.payload};
        }
        default:
            return state;
    }
}

import BB20Service from "./BB20Service";
import FetchWrapper from "./FetchWrapper";
import {
    Airport,
    City,
    Exporter,
    ExportLocation,
    Importer,
    ImportLocation,
    LoadingUnitDestination,
    LoadingUnitDestinationLmcCombination,
    LoadingUnitLmc,
    PossibleLaneCombination,
    Warehouse
} from "@/models/Masterdata";

class MasterDataService extends BB20Service {
    constructor() {
        super(process.env["REACT_APP_MASTER_DATA_SERVICE_URL"]!);
    }

    async getAllAirports(): Promise<Airport[]> {
        return FetchWrapper.GET(`${this.serviceUrl}/airports`);
    }

    async getAllImportLocations(): Promise<ImportLocation[]> {
        return FetchWrapper.GET(`${this.serviceUrl}/import-locations`);
    }

    async getAllExportLocations(): Promise<ExportLocation[]> {
        return FetchWrapper.GET(`${this.serviceUrl}/export-locations`);
    }

    async getAllWarehouses(): Promise<Warehouse[]> {
        return FetchWrapper.GET(`${this.serviceUrl}/warehouses`);
    }

    async getDefaultWarehouse(): Promise<Warehouse> {
        return FetchWrapper.GET(`${this.serviceUrl}/warehouses/default`);
    }

    async getAllImporters(): Promise<Importer[]> {
        return FetchWrapper.GET(`${this.serviceUrl}/importers`);
    }

    async getAllExporters(): Promise<Exporter[]> {
        return FetchWrapper.GET(`${this.serviceUrl}/exporters`);
    }

    async getAllCities(): Promise<City[]> {
        return FetchWrapper.GET(`${this.serviceUrl}/cities`);
    }

    async getPossibleLaneCombinations(): Promise<PossibleLaneCombination[]> {
        return FetchWrapper.GET(`${this.serviceUrl}/lane-combinations`);
    }

    async getLoadingUnitDestinationLmcCombination(): Promise<LoadingUnitDestinationLmcCombination[]> {
        return FetchWrapper.GET(`${this.serviceUrl}/loadingunit-destination-lmc`);
    }

    async getLoadingUnitDestinations(): Promise<LoadingUnitDestination[]> {
        return FetchWrapper.GET(`${this.serviceUrl}/loadingunit-destination-lmc/destination`);
    }

    async getLoadingUnitLmcs(): Promise<LoadingUnitLmc[]> {
        return FetchWrapper.GET(`${this.serviceUrl}/loadingunit-destination-lmc/lmc`);
    }
}

export default MasterDataService;
import FetchWrapper from "./FetchWrapper";
import {ServiceHealth} from "../models/ServiceHealth";

class BB20Service {
    serviceUrl: string

    constructor(url: string) {
        this.serviceUrl = url
    }

    health(): Promise<ServiceHealth> {
        const urlBasePart = this.serviceUrl.slice(0, this.serviceUrl.lastIndexOf('/'))
        const healthUrl = `${urlBasePart}/actuator/health`
        return FetchWrapper.GET(healthUrl)
    }
}

export default BB20Service

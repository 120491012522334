import "react-app-polyfill/stable"
import "core-js"
import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import {Provider} from "react-redux"
import AuthService from "./services/AuthService";
import {Actions} from "./redux/actions/action";
import store from "./redux/store";
import {storeMasterDataInRedux} from "./views/common";
import { Dispatch } from 'redux';

const container = document.getElementById('root')!
const root = ReactDOM.createRoot(container);
const renderApp = () => root.render(
    <Provider store={store}>
        <App/>
    </Provider>
)

// Call the thunk function (launchApp) to:
// 1. initialize keycloak adapter then render the app content (renderApp).
// 2. set the user details (username, roles, token, authenticated) in redux store.
store.dispatch(launchApp);

// Thunk function
async function launchApp(dispatch: Dispatch) {
    // Initialize the keycloak adapter
    await AuthService.initKeycloak(renderApp);
    // Save the user details and master data in redux store
    dispatch({type: Actions.USER_DETAILS.UPDATE_USER, payload: AuthService.getUser()})
    storeMasterDataInRedux(dispatch)
    // store timezone in redux
    dispatch({type: Actions.TIME_ZONE.SET_TIME_ZONE, payload: Intl.DateTimeFormat().resolvedOptions().timeZone})
}


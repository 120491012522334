import {Actions} from "../actions/action";
import {User} from "../../models/User";

const initialUserState: User = {
    username: undefined,
    roles: [],
    token: undefined,
    authenticated: false
}

export default function userReducer(state: User = initialUserState, action: any): User {
    switch (action.type) {
        case Actions.USER_DETAILS.UPDATE_USERNAME: {
            return {...state, username: action.payload};
        }
        case Actions.USER_DETAILS.UPDATE_ROLES: {
            return {...state, roles: action.payload};
        }
        case Actions.USER_DETAILS.UPDATE_TOKEN: {
            return {...state, token: action.payload};
        }
        case Actions.USER_DETAILS.UPDATE_IS_AUTHENTICATED: {
            return {...state, authenticated: action.payload};
        }
        case Actions.USER_DETAILS.UPDATE_USER: {
            return {
                ...state,
                username: action.payload.username,
                roles: action.payload.roles,
                token: action.payload.token,
                authenticated: action.payload.authenticated
            };
        }
        case Actions.USER_DETAILS.LOG_OUT_USER: {
            return initialUserState;
        }
        default:
            return state;
    }
}
